<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="sm:text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="permission.title"></app-i18n>
          </h2>

          <section class="relative">
            <div style="width: 100%; height: 80vh" class="flex justify-center" v-if="findLoading">
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div>

            <div v-if="pages && pages.length">
              <div class="pt-5">
                <div class="flex justify-center">
                  <div class="w-full" style="max-width: 900px;">
                    <div class="flex items-center gap-3 mb-5">
                      <input id="select-all" type="checkbox" v-model="selectAll" @change="OnChangeSelectAll()" class="form-check-input border" />
                      <label for="select-all" class="cursor-pointer select-none">{{ selectAll ? i18n('permission.unselectAll') : i18n('permission.selectAll') }}</label>
                    </div>
                    <div class="box box-container w-full p-0 sm:p-5 overflow-auto">
                      <div class="flex items-center justify-between w-full p-3" v-for="(page, index) in pages" :key="index">
                        <div class="page-label" :class="{ 'text-gray-500': !page.selected, 'text-black font-bold': page.selected }">
                          {{ i18n(`pagesAccess.${page.id}`) }}
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox" :checked="page.selected" v-model="page.selected" />
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="flex justify-center items-center mt-5 sm:mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-36 text-white cursor-pointer"
                  :class="!saveLoading ? 'w-24' : ''"
                  :disabled="saveLoading || findLoading"
                  :loading="saveLoading"
                  @click="doSubmit()"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon icon="three-dots" color="#FFFFFF" style="margin: 0 4px;" />
                  </template>
                </AppButton>
                <button type="button" class="btn bg-theme-31 text-black w-24 ml-3 mr-3 cursor-pointer" @click="doCancel()">
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import PagesAccess from '@/security/pages-access.js'

export default {
  props: {
    id: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      selectAll: false,
      pages: []
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      language: 'layout/currentLanguageCode',
      record: 'admin/form/record',
      findLoading: 'admin/form/findLoading',
      saveLoading: 'admin/form/saveLoading'
    })
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.notifications')
  },
  watch: {
    isRTL() {
      document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.notifications')
    }
  },
  async mounted() {
    await this.doFind(this.id)
    this.pages = PagesAccess.selectOptions
    this.record.pagesAccess.forEach(pageAccessId => {
      const index = this.pages.map(page => page.value).indexOf(pageAccessId)
      this.pages[index].selected = true
    })
    this.selectAll = this.pages.length === this.pages.filter(page => page.selected).length
  },
  methods: {
    ...mapActions({
      doFind: 'admin/form/doFind',
      doSetAdminAccess: 'admin/form/doSetAdminAccess'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    OnChangeSelectAll() {
      this.pages.forEach(page => {
        page.selected = !!this.selectAll
      })
    },
    doCancel() {
      this.$router.back()
    },
    async doSubmit() {
      const pagesAccess = this.pages.filter(page => page.selected).map(page => page.value)
      await this.doSetAdminAccess({
        id: this.id,
        pagesAccess: pagesAccess
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.box-container {
  max-width: 900px;
  max-height: 60vh;
}
.page-label {
  font-size: 16px;
  // font-weight: 500;
}
@media (max-width: 1439px) {
  .page-label {
    font-size: calc(12px + (16 - 12) * ((100vw - 320px) / (1440 - 320))) !important;
  }
}
.form-check-input {
  @apply border-theme-33 bg-theme-33;
  &:checked {
    @apply border-theme-31 bg-theme-31;
  }
}
</style>

<style lang="scss" scoped>
.switch {
  position: relative;
  display: inline-block;
  // width: 60px;
  width: 57px;
  height: 25px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #172e70;
}
input:focus + .slider {
  box-shadow: 0 0 1px #172e70;
}
input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
@media (max-width: 1439px) {
  .switch {
    width: calc(30px + (57 - 30) * ((100vw - 320px) / (1440 - 320)));
    height: calc(15px + (25 - 15) * ((100vw - 320px) / (1440 - 320)));
  }
  .slider:before {
    height: calc(10px + (20 - 10) * ((100vw - 320px) / (1440 - 320)));
    width: calc(10px + (20 - 10) * ((100vw - 320px) / (1440 - 320)));
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(calc(15px + (30 - 15) * ((100vw - 320px) / (1440 - 320))));
    -ms-transform: translateX(calc(15px + (30 - 15) * ((100vw - 320px) / (1440 - 320))));
    transform: translateX(calc(15px + (30 - 15) * ((100vw - 320px) / (1440 - 320))));
  }
}
</style>
